<template>
  <div class="price-container">
    <div class="top-banner">
      <div>
        <div class="banner-title">Pricing</div>
        <div class="banne-desc">
          Subscribe to below pricing plan in order continue using our features
        </div>
      </div>
    </div>
    <loader v-if="false"></loader>
    <div class="plan-section">
      <div v-for="plan in plans" :key="plan._id" class="plan-data">
        <div class="plan-title cl-Mako">{{ plan.name }}</div>
        <div class="plan-price-data">
          <div class="plan-price">Rs.{{ plan.price.amount }}</div>
          <div class="plan-price-desc cl-DustyGray2">
            {{ plan.yearly_plan ? `Per year` : `Per month` }}
          </div>
        </div>
        <div class="plan-features">
          <div
            v-for="(feature, index) in plan.features"
            :key="`feature-${index}`"
            class="feature-line"
          >
            <div class="icon">
              <inline-svg :src="'tick'"></inline-svg>
            </div>
            <div class="feature-text cl-Mako">{{ feature }}</div>
          </div>
        </div>
        <nitrozen-button
          class="subscribe-btn"
          v-flat-btn
          theme="secondary"
          @click="subscribeToPlan(plan._id)"
        >
          Subscribe
        </nitrozen-button>
      </div>
    </div>
  </div>
</template>



<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
.top-banner {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e31be;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  color: #fff;
  .banner-title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }
  .banne-desc {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    max-width: 300px;
  }
}
.plan-section {
  display: flex;
  justify-content: center;
  padding: 24px;
  .plan-data {
    padding: 24px;
    border: 1px solid @Iron;
    border-radius: 15px;
    width: 300px;
    text-align: center;
    .plan-title {
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 24px;
    }
    .plan-price-data {
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid @Iron;
      .plan-price {
        font-size: 24px;
        font-weight: 600;
      }
      .plan-price-desc {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .plan-features {
      padding: 24px 0;
      border-top: 1px solid @Iron;
      .feature-line {
        display: flex;
        align-items: center;
        padding: 0 16px 12px;
        .feature-text {
          text-align: left;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>

<script>
import loader from "@/components/common/adm-loader";
import MainService from "@/services/main.service";
import inlineSvg from "@/components/common/inline-svg.vue";
import { NitrozenButton, flatBtn } from "@gofynd/nitrozen-vue";
import { setCompany } from "@/helper/utils";
export default {
  name: "pricing-page",
  components: {
    NitrozenButton,
    loader,
    "inline-svg": inlineSvg,
  },
  directives: {
    flatBtn,
  },
  data() {
    return {
      plans: [
        // {
        //   name: "",
        //   tagline: "",
        //   price: {
        //     amount: 0,
        //     currency: "INR",
        //   },
        //   features: ["Feature 1", "Feature 2", "Feature 3"],
        // },
      ],
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.params.company_id) {
      setCompany(to.params.company_id);
    }
    next();
  },
  beforeMount() {
    this.getPlans();
  },
  methods: {
    subscribeToPlan(plan_id) {
      MainService.subscribePlan(plan_id)
        .then(({ data }) => {
          if (data.confirm_url) {
            window.location.href = data.confirm_url;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPlans() {
      MainService.getPlans()
        .then(({ data }) => {
          this.plans = data.plans;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
